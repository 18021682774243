import { types as baseTypes } from '@contentpepper-publisher/base';

// register each available plugin for this app
import { registerPlugin as registerDashboardPlugin } from '@contentpepper-publisher/plugin-dashboard';
import { registerPlugin as registerPluginAuth } from '@contentpepper-publisher/plugin-auth';
import { registerPlugin as registerPluginGlobalSearch } from '@contentpepper-publisher/plugin-global-search';
import { registerPlugin as registerPluginNotifications } from '@contentpepper-publisher/plugin-notifications';
import { registerPlugin as registerPluginLanguageSwitcher } from '@contentpepper-publisher/plugin-language-switcher';
import { registerPlugin as registerPluginProfileMenu } from '@contentpepper-publisher/plugin-profile-menu';
import { registerPlugin as registerPluginFooterMenu } from '@contentpepper-publisher/plugin-footer-menu';
import { registerPlugin as registerPluginChannels } from '@contentpepper-publisher/plugin-channels';
import { registerPlugin as registerPluginContentEditor } from '@contentpepper-publisher/plugin-content-editor';
import { registerPlugin as registerContentPlugin } from '@contentpepper-publisher/plugin-content';
import { registerPlugin as registerAnalyticsPlugin } from '@contentpepper-publisher/plugin-analytics';
import { registerPlugin as registerPluginMatomoManagement } from '@contentpepper-publisher/plugin-matomo-management';
import { registerPlugin as registerPluginTitleBanner } from '@contentpepper-publisher/plugin-title-banner';
import { registerPlugin as registerPluginCleverreach } from '@contentpepper-publisher/plugin-cleverreach';
import { registerPlugin as registerPluginFormFields } from '@contentpepper-publisher/plugin-form-fields';
import { registerPlugin as registerPluginMegadraft } from '@contentpepper-publisher/plugin-megadraft';
import { registerPlugin as registerPluginSeo } from '@contentpepper-publisher/plugin-seo';
import { registerPlugin as registerPluginStructure } from '@contentpepper-publisher/plugin-structure';
import { registerPlugin as registerPluginUserManagement } from '@contentpepper-publisher/plugin-user-management';
import { registerPlugin as registerPluginUrlManagement } from '@contentpepper-publisher/plugin-url-management';
import { registerPlugin as registerPluginAssetManagement } from '@contentpepper-publisher/plugin-asset-management';
import { registerPlugin as registerPluginPixxio } from '@contentpepper-publisher/plugin-pixxio';

import { register as registerUi } from '@contentpepper-publisher/ui';

export const availablePlugins: baseTypes.PluginConfig[] = [
  registerUi(),
  registerDashboardPlugin(),
  registerAnalyticsPlugin(),
  registerPluginMatomoManagement(),
  registerPluginAuth(),
  registerPluginGlobalSearch(),
  registerPluginNotifications(),
  registerPluginLanguageSwitcher(),
  registerPluginProfileMenu(),
  registerPluginFooterMenu(),
  registerPluginChannels(),
  registerPluginContentEditor(),
  registerContentPlugin(),
  registerPluginTitleBanner(),
  registerPluginCleverreach(),
  registerPluginFormFields(),
  registerPluginMegadraft(),
  registerPluginSeo(),
  registerPluginStructure(),
  registerPluginUserManagement(),
  registerPluginUrlManagement(),
  registerPluginAssetManagement(),
  registerPluginPixxio()
];

// would be loaded from server side
export const activePlugins: string[] = [
  'cp-ui',
  'cp-dashboard',
  'cp-auth',
  'cp-global-search',
  'cp-language-switcher',
  'cp-notifications',
  'cp-profile-menu',
  'cp-footer-menu',
  'cp-channels',
  'cp-content-editor',
  'cp-content',
  'cp-title-banner',
  'cp-channel-facebook',
  'cp-channel-fCMS',
  'cp-channel-instagram',
  'cp-channel-linkedin',
  'cp-channel-twitter',
  'cp-channel-whatsapp',
  'cp-matomo-management',
  'cp-channel-web',
  'cp-cleverreach',
  'cp-form-fields',
  'cp-megadraft',
  'cp-seo',
  'cp-structure',
  'cp-user-management',
  'cp-url-management',
  'cp-asset-management',
  'cp-pixxio'
];
